import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "../index.css";
import BgImage from "../Assets/BgImage1.png";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

import { Routes, Route, useParams } from 'react-router-dom';

const Certificate = () => {


  let { id } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const searchParams = new URLSearchParams(location.search);
  const ids = searchParams.get('id');

  console.log(ids,"ok")

  useEffect(() => {
    if (!ids) return;

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://samarth.prod.api.sapioglobal.com/skilling/get-certificate`,
          {
            params: {
              certificate_no: ids,
            },
          }
        );

        if (response.data.status === "failed") {
          throw new Error(response.data.error);
        }

        setData(response.data.data);
        console.log(response.data.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [ids]);

  return (
    <div className="flex justify-center items-center min-h-screen">
      <div
        className="flex gap-0 max-h-[850px] max-w-[470px] min-w-[410px] rounded-[6px] p-3 pb-7"
        style={{
          backgroundImage: `url(${BgImage})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          // height: "570px",
          width: "470px",
        }}
      >
        <div className="flex flex-col items-center justify-start h-full p-6 pl-10 mt-7">
          <h2
            className=" pt-[-5px] text-[24px] font-normal leading-[57px] tracking-[0.9px] text-center"
            style={{ fontFamily: "Copperplate", color: "#FF5C00" }}
          >
            CERTIFICATE DETAILS
          </h2>
          <div className="text-black text-left leading-[24px] w-full">
            {loading ? (
              <Box sx={{ width: "100%", padding: "13px" }}>
                {Array.from({ length: 6 }).map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="text"
                    width="100%"
                    height={30}
                    animation="wave"
                    style={{ marginBottom: "10px" }}
                  />
                ))}
              </Box>
            ) : error ? (
              <p>{error}</p>
            ) : data ? (
              <>
                <p className="flex flex-wrap  items-center gap-x-2 px-2">
                  <span
                    className="font-oswald text-[14px] font-normal leading-[57px] tracking-[0.5px]"
                    style={{ fontFamily: "oswald" }}
                  >
                    Student Name:{" "}
                  </span>
                  <span
                    className="font-oswald text-[14px] font-bold leading-[57px] tracking-[0.5px]"
                    style={{ fontFamily: "oswald" }}
                  >
                    {data.student_name}
                  </span>
                </p>

                <p className="flex flex-wrap items-center gap-x-2 px-2">
                  <span
                    className="font-oswald text-[14px] font-normal leading-[57px] tracking-[0.5px]"
                    style={{ fontFamily: "oswald" }}
                  >
                    Certificate No:{" "}
                  </span>
                  <span
                    className="font-oswald text-[14px] font-bold leading-[57px] tracking-[0.5px]"
                    style={{ fontFamily: "oswald" }}
                  >
                    {data.certificate_no}
                  </span>
                </p>
                <p className="flex flex-wrap items-center gap-x-2 px-2">
                  <span
                    className="font-oswald text-[14px] font-normal leading-[57px] tracking-[0.5px]"
                    style={{ fontFamily: "oswald" }}
                  >
                    Course Name:{" "}
                  </span>
                  <span
                    className="font-oswald text-[14px] font-bold leading-[57px] tracking-[0.5px]"
                    style={{ fontFamily: "oswald" }}
                  >
                    {data.course_name}
                  </span>
                </p>
                <p className="flex flex-wrap items-center gap-x-2 px-2">
                  <span className="font-oswald text-[14px] font-normal tracking-[0.5px]">
                    Batch Code:
                  </span>
                  <span
                    className={`font-oswald text-[14px] font-bold tracking-[0.5px] ${
                      data.batch_code.length > 10
                        ? "leading-[40 px]"
                        : "leading-[57px]"
                    }`}
                  >
                    {data.batch_code}
                  </span>
                </p>

                <p className="flex flex-wrap items-center gap-x-2 px-2">
                  <span
                    className="font-oswald text-[14px] font-normal leading-[57px] tracking-[0.5px]"
                    style={{ fontFamily: "oswald" }}
                  >
                    Start Date:{" "}
                  </span>
                  <span
                    className="font-oswald text-[14px] font-bold leading-[57px] tracking-[0.5px]"
                    style={{ fontFamily: "oswald" }}
                  >
                    {data.start_date}
                  </span>
                </p>
                <p className="flex flex-wrap items-center gap-x-2 px-2">
                  <span
                    className="font-oswald text-[14px] font-normal leading-[57px] tracking-[0.5px]"
                    style={{ fontFamily: "oswald" }}
                  >
                    End Date:{" "}
                  </span>
                  <span
                    className="font-oswald text-[14px] font-bold leading-[57px] tracking-[0.5px]"
                    style={{ fontFamily: "oswald" }}
                  >
                    {data.end_date}
                  </span>
                </p>
              </>
            ) : (
              <p>No data available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certificate;
